@import '../../scss/variables';

.radio-toggle-switch-container {
  display: flex;
  justify-content: center;
  margin-bottom: $spacing-xl;
  min-height: 42px;

  .radio-toggle-switch {
    overflow: hidden;
    background-color: $gray-80;
    border: 1px solid $blue-100;
    border-radius: $spacing-large;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;

    &.radio-toggle-switch-fixed {
      position: fixed;
      top: 90px;
      z-index: 99;
      left: calc(50% - 95px);
    }

    input {
      position: absolute !important;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      width: 1px;
      border: 0;
      overflow: hidden;
    }

    label {
      display: inline-block;
      width: 94px;
      font-size: 16px;
      font-weight: normal;
      text-align: center;
      text-shadow: none;
      padding: 8px 16px;
      transition: all 0.2s ease-in-out;
      margin-bottom: 0;

      &:hover {
        cursor: pointer;
      }
    }

    input:checked + label {
      border-radius: $spacing-large;
      background-color: $blue-100;
      color: #fff;
    }
  }
}
