@import 'scss/variables.scss';

.total-card {
  min-height: 300px;
  border: 1px solid #d0daea;
  border-radius: 10px;

  h2 {
    font-size: $font-size-xxxlarge;
    font-weight: $font-weight-bold;
    white-space: nowrap;
  }

  h3 {
    font-size: $font-size-xlarge;
    font-weight: $font-weight-bold;
    white-space: nowrap;
  }

  h3:last-child {
    margin: 0;
  }

  h4 {
    font-size: $font-size-xlarge;
    font-weight: $font-weight-bold;
    white-space: nowrap;
  }

  h5 {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    white-space: nowrap;
  }

  .card-title {
    font-weight: $font-weight-bolder;
  }

  .card-progressbar {
    width: 100%;
    text-align: center;

    .semi-circular-progressbar-wrapper {
      margin: 0 auto;
    }
  }

  .card-color {
    min-width: 220px;
    flex-shrink: 0;
    padding: 20px;
  }
}

.card-shadow {
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.22);
}
