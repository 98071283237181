@import '../scss/variables';

.reporting-page {
  background-color: $background-blue;
  border-radius: $spacing-regular;

  .tab-content {
    background-color: unset !important;
    padding: $spacing-large !important;
  }

  .nav-tabs {
    padding-top: $spacing-medium;
    border-bottom: 1px solid $gray-80;
    li {
      margin-bottom: 0px;

      .tab-item {
        color: $black-85;
        background-color: unset;
        padding: $spacing-small $spacing-large;

        &.active {
          color: $blue-200;
          font-weight: $font-weight-bold;
          border-color: $background-blue;
          border-bottom: 1px solid $blue-200;
        }
      }
    }
  }

  table {
    font-size: 12px;
    border-collapse: separate;
    border-spacing: 0;
    background-color: #fff;

    thead {
      position: sticky;
      top: 0;
      background-color: #fff;
      box-shadow: 0 4px 15px -2px rgba(0, 0, 0, 0.15);
    }

    th {
      font-weight: 500;
    }

    th,
    td {
      border-bottom: 1px solid;
      border-right: 1px solid;
      border-color: #cbcbcb;
      white-space: nowrap;
      vertical-align: middle;
    }

    th:first-child,
    td:first-child {
      border-left: 1px solid #cbcbcb;
    }

    tr:first-child th {
      border-top: 1px solid #cbcbcb;
      font-size: 16px;
    }

    th button.sorting-button {
      width: 100%;
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      outline: inherit;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    th button.sorting-button svg {
      color: #b4b4b4;
    }

    td button {
      border: 0;
      outline: 0;
      font-size: 12px;
    }
  }
}

.business-inteligence {
  label {
    font-size: 12px;
    font-weight: 500;
  }
  .select__menu {
    font-size: 12px;
  }

  .select-account {
    .select__multi-value__label {
      max-width: 100px;
      white-space: break-spaces;
      text-align: center;
    }
  }
  .select__multi-value {
    background-color: #f1f1f1;
  }

  .select__placeholder {
    font-size: 12px;
  }

  .select__value-container {
    font-size: 14px;
    max-height: 140px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .select__multi-value__label {
      font-size: 11px;
    }
  }
}
