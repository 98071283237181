@import '../../../scss/variables';

.month-close {
  h1 {
    font-size: $spacing-large;
    font-weight: $font-weight-bolder;
    margin-bottom: $spacing-large;
  }

  form {
    .text-danger {
      font-size: 12px;
    }
  }
}
